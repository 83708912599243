<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card">
        <div class="card-header">
          <h4>تعديل الجهاز</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <h4 for="">إسم الجهاز</h4>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="title"
              required
              id=""
              aria-describedby="helpId"
              placeholder="اكتب اسم الجهاز هنا.."
              :value="device.title"
            />
          </div>
          <div class="form-group">
            <h4 for="">IP</h4>
            <input
              type="text"
              class="form-control form-control-lg"
              required
              ref="ip"
              id=""
              aria-describedby="helpId"
              placeholder="اكتب الـIP هنا.."
              :value="device.ip"
            />
            <small id="helpId" class="form-text text-muted"
              >مثال: 192.168.1.105</small
            >
          </div>
          <div class="col-12 text-center">
            <button class="btn btn-primary" @click="add()">
              تعديل الجهاز
              <i class="fa fa-arrow-left"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      device: {},
    };
  },
  methods: {
    add() {
      var _g = this;
      if (this.$refs.title.value != "" && this.$refs.title.value != undefined) {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "جاري التحميل..",
            icon: "WarningIcon",
            variant: "warning",
          },
        });
        axios
          .post(localStorage.getItem("api0") + "/devices/edit", {
            title: this.$refs.title.value,
            ip: this.$refs.ip.value,
            id: window.location.pathname.split("/edit/")[1],
            jwt: localStorage.getItem("jwt"),
          })
          .then(function (r) {
            _g.$toast.clear();
            if (r.data.status == 100) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم تعديل الجهاز بنجاح",
                  icon: "WarningIcon",
                  variant: "success",
                },
              });
              _g.$router.push("/fingerprint");
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: r.data.response,
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast.clear();
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      } else {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "هناك حقول مطلوبة",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      }
    },
  },
  created() {
    var _g = this;
    axios
      .post(localStorage.getItem("api0") + "/devices/device", {
        id: window.location.pathname.split("/edit/")[1],
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        _g.device = r.data;
      });
  },
};
</script>

<style>
</style>
